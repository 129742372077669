import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { Layout, SEOTags } from "../components";
import "../styles/form-toast.scss";
import * as styles from "../styles/form.module.scss";
import { submitFormData } from "../utilities/formUtilities";

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState([]);

  const formSchema = yup
    .object({
      form: yup.string(),
      name: yup.string().required("This field is required"),
      email: yup.string().required("This field is required"),
      company: yup.string(),
      subject: yup.string().required("This field is required"),
      message: yup.string().required("This field is required"),
      link: yup.string().url("Please enter a valid url"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    const cache = JSON.parse(sessionStorage.getItem("contactForm"));
    if (cache) setInitialValues(cache);
  }, []);

  useEffect(() => {
    reset({
      form: "Work with us",
      name: initialValues.name,
      email: initialValues.email,
      company: initialValues.company,
      subject: initialValues.subject,
      message: initialValues.message,
      link: initialValues.link,
    });
  }, [reset, initialValues]);

  const watchFields = watch();

  useEffect(() => {
    const filled = !Object.values(watchFields).every(
      (item, index) =>
        (index === 0 && item === "Work with us") ||
        typeof item === "undefined" ||
        item === ""
    );
    if (filled)
      sessionStorage.setItem("contactForm", JSON.stringify(watchFields));
    else sessionStorage.removeItem("contactForm");
  }, [watchFields]);

  const onSubmit = handleSubmit(async (data) => {
    const onSuccess = () => {
      setIsSubmitting(true);
      toast("Successfully submitted form", { type: "success" });
      setTimeout(() => {
        sessionStorage.removeItem("contactForm");
        window.location.reload();
      }, 1500);
    };

    const onFormcarryError = () => {
      toast("Error submitting the form\nPlease try again", {
        type: "error",
      });
    };

    const onNetworkError = () => {
      toast("Error submitting the form\nPlease refresh the page", {
        type: "error",
      });
    };

    try {
      submitFormData(
        "https://formcarry.com/s/JJNkxKUzJ",
        data,
        onSuccess,
        onFormcarryError,
        onNetworkError
      );
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <Layout layoutClassName="contact-page" hideContactLink={true}>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        pauseOnHover={false}
      />
      <div className={styles.formpage}>
        <h1>Work with us</h1>
        <form className={styles.form} onSubmit={onSubmit}>
          <Row className={`${styles.row} justify-content-between`}>
            <Col xs={12} lg={6} className={styles.text}>
              <h3 className={styles.title}>
                Let's create something brilliant together.
              </h3>
              <p className={styles.subtitle}>
                Think we might be the right team for your next project? We'd
                love to hear from you.
              </p>
            </Col>
            <Col xs={12} lg={6} className={styles.fields}>
              <div className={styles.field}>
                <label htmlFor="name">FULL NAME*</label>
                <input
                  id="name"
                  placeholder="Enter full name"
                  {...register("name")}
                />
                {errors.name && (
                  <p className={styles.error}>{errors.name.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="email">EMAIL*</label>
                <input
                  id="email"
                  placeholder="Enter email"
                  {...register("email")}
                />
                {errors.email && (
                  <p className={styles.error}>{errors.email.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="company">COMPANY</label>
                <input
                  id="company"
                  placeholder="Enter company"
                  {...register("company")}
                />
                {errors.company && (
                  <p className={styles.error}>{errors.company.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="subject">SUBJECT*</label>

                <input
                  id="subject"
                  placeholder="Enter subject"
                  {...register("subject")}
                />
                {errors.subject && (
                  <p className={styles.error}>{errors.subject.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="message">MESSAGE*</label>
                <textarea
                  id="message"
                  placeholder="Enter message"
                  {...register("message")}
                />
                {errors.message && (
                  <p className={styles.error}>{errors.message.message}</p>
                )}
              </div>
              <div className={styles.field}>
                <label htmlFor="link">LINK TO PROJECT DOCUMENTS</label>
                <input
                  id="link"
                  placeholder="Insert Link to any important documents and files"
                  {...register("link")}
                />
                {errors.link && (
                  <p className={styles.error}>{errors.link.message}</p>
                )}
                <p className={styles.helper}>
                  Please make sure the files are made public so that we can
                  review your documents
                </p>
              </div>
              <button type="submit" disabled={isSubmitting}>
                Send form
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </Layout>
  );
};

export default ContactForm;

export const Head = ({ location }) => (
  <SEOTags title={"Contact | Mahusai Global"} pathname={location.pathname} />
);
